import request from "../utils/request.js"

// 中心管理
export function zhongxingGuan(data) {
    return request({
        url: `Core/getCoreIntroduce?category_id=${data}`,
        method: "get",
    });
}
// 专家团队
export function zhuanjiaTuan(data, id) {
    return request({
        url: `Index/getExpertList?category_id=${id}&page=${data}`,
        method: "get",
    });
}
// 荣耀
export function rongYaose(data, id) {
    return request({
        url: `Core/getCoreList?category_id=${id}&page=${data}`,
        method: "get",
    });
}
// 公式公告
export function gongshigong(data) {
    return request({
        url: `Notice/getNoticeList?page=${data}`,
        method: "get",
    });
}
// 公式公告详情
export function gongshiDelis(data) {
    return request({
        url: `Notice/detail?id=${data}`,
        method: "get",
    });
}
// 产品设备
export function chanpingShe(data, id) {
    return request({
        url: `Innovate/getInnovateList?page=${data}&category_id=${id}`,
        method: "get",
    });
}
// 产品设备详情
export function changpingDeli(data) {
    return request({
        url: `Innovate/detail?id=${data}`,
        method: "get",
    });
}
// 行业培训
export function hangyePeix(data) {
    return request({
        url: `Category/getDetail?page=1&category_id=${data}`,
        method: "get",
    });
}
// 服务平台
export function fuwupingTai(data) {
    return request({
        url: `ServerDetail/getSeverDetail?cate_id=${data}`,
        method: "get",
    });
}
// 房屋e院
export function fnagwuYiyuan(data) {
    return request({
        url: `House/getHouseOne?page=1&category_id=${data}`,
        method: "get",
    });
}
// 联系我们
export function lianxiWomen(data) {
    return request({
        url: `Index/getIntroduce?type_id=${data}`,
        method: "get",
    });
}