import request from "../utils/request.js"

// 轮播图
export function lumboImg(data) {
    return request({
        url: `Index/getBannerList?category_id=${data}`,
        method: "get",
    });
}
// 公告
export function gonggaoshili() {
    return request({
        url: `Index/getNotice`,
        method: "get",
    });
}
// 专家团队
export function zhanjiatuan(data) {
    return request({
        url: `Index/getExpertList?category_id=${data}`,
        method: "get",
    });
}
// 首页新闻动态
export function zhongxDong(data) {
    return request({
        url: `Index/getHomeNewsList?category_id=${data}`,
        method: "get",
    });
}
// 新闻动态
export function xingwenDongt(data, id) {
    return request({
        url: `News/getNewsList?page=${data}&category_id=${id}`,
        method: "get",
    });
}
// 新闻动态详情
export function xingwenDelis(data) {
    return request({
        url: `News/detail?id=${data}`,
        method: "get",
    });
}
// 中心简介
export function zhongxJianjie(data) {
    return request({
        url: `Index/getIntroduce?type_id=${data}`,
        method: "get",
    });
}
// 产品设备
export function chanpingShe() {
    return request({
        url: `Index/getHomeProduct`,
        method: "get",
    });
}
// 荣耀
export function rongyaoZhi(data) {
    return request({
        url: `Index/getExpertList?category_id=${data}`,
        method: "get",
    });
}