import VueRouter from 'vue-router'
import store from '../store/index.js'
import homepage from '../view/homePage.vue'
import zhongxingPage from '../view/zhongxingPage.vue'
import gongshiPage from '../view/gongshiPage.vue'
import gongshiDeli from '../view/gongshiDeli.vue'
import xingwenPage from '../view/xingwenPage.vue'
import yanfasePage from '../view/yanfasePage.vue'
import hangyePage from '../view/hangyePage.vue'
import rencaikPage from '../view/rencaikPage.vue'
import fuwupingPage from '../view/fuwupingPage.vue'
import fangwuPage from '../view/fangwuPage.vue'
import lianxiPage from '../view/lianxiPage.vue'
const routes = [{ //首页
        path: '/',
        component: homepage,
        meta: {
            state: 0,
        },
    },
    { //中心概况
        path: '/zhongxingPage',
        component: zhongxingPage,
        meta: {
            state: 1,
        },
    },
    { //公式公告
        path: '/gongshiPage',
        component: gongshiPage,
        meta: {
            state: 2,
        },
    }, { //公式公告详情
        path: '/gongshiDeli',
        component: gongshiDeli,
        meta: {
            state: 2,
        },
    },
    { //新闻动态
        path: '/xingwenPage',
        component: xingwenPage,
        meta: {
            state: 3,
        },
    },
    { //研发创新
        path: '/yanfasePage',
        component: yanfasePage,
        meta: {
            state: 4,
        },
    },
    { //行业培训
        path: '/hangyePage',
        component: hangyePage,
        meta: {
            state: 5,
        },
    },
    { //人才库
        path: '/rencaikPage',
        component: rencaikPage,
        meta: {
            state: 6,
        },
    },
    { //服务平台
        path: '/fuwupingPage',
        component: fuwupingPage,
        meta: {
            state: 7,
        },
    },
    { //房屋E院
        path: '/fangwuPage',
        component: fangwuPage,
        meta: {
            state: 8,
        },
    },
    { //联系我们
        path: '/lianxiPage',
        component: lianxiPage,
        meta: {
            state: 9,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            // vue2.0  x  y  控制
            // vue3.0  left  top 控制
            return {
                x: 0,
                y: 0
            }
        }
    }
})
// 全局前置路由守卫， 表示在每次切换路由之前调用， 针对所有的路由
router.beforeEach((to, from, next) => { // to表示去哪个路由，from表示来自哪个路由，next表示放行
    store.commit('user/setTitle', to.meta.state)
    next()
})
export default router;