const state = {
    title: 0
}
const getters = {
    getType: (state) => state.type,
}

const mutations = {
    setTitle: (state, val) => {
        state.title = val
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}