<template>
    <div>
        <div class="mana"><img src="../assets/fuyebeij.png" alt=""></div>
        <div class="us">
            <div class="us-one">
                <el-image style="width: 100%" :src="url" :preview-src-list="srcList">
                </el-image>
            </div>
            <div class="us-two">
                <h5>联系我们</h5>
                <p><i class="iconfont icon-dizhi"></i>公司地址：{{delisDat.address}}</p>
                <p><i class="iconfont icon-youjian1"></i>邮编：{{delisDat.postcode}}</p>
                <h5>客服服务</h5>
                <p><i class="iconfont icon-dianhua"></i>咨询电话：{{delisDat.phone}}</p>
                <p><i class="iconfont icon-3 spe"></i>市场总监联系电话：{{delisDat.mobile}}</p>
                <p><i class="iconfont icon-youjian"></i>客服邮编：{{delisDat.email}}</p>
            </div>
        </div>
    </div>
</template>
=
<script>
    import {
        lianxiWomen
    } from '../api/yemian.js'
    export default {
        data() {
            return {
                delisDat: '',
                url: require('../assets/ditudingwei.png'),
                srcList: [
                    require('../assets/ditudingwei.png')
                ]
            }
        },
        mounted() {
            this.getfuwuPing()
        },
        methods: {
            async getfuwuPing() {
                let data = await lianxiWomen(6)
                this.delisDat = data.data
            },
        }
    }
</script>

<style>
    @media screen and (min-width: 600px) {
        .us {
            padding: 50px 0;
            padding-left: 20px;
            width: 1100px;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            color: #4f4f4f
        }

        .us .us-one {
            width: 50%
        }

        .us .us-one img {
            width: 100%;
            height: auto
        }

        .us .us-two {
            flex: 1;
            overflow: hidden;
            padding-left: 100px;
            color: #333
        }

        .us .us-two h5 {
            font-size: 18px;
            color: #0075d8
        }

        .us .us-two h5,
        .us .us-two p {
            margin: 15px 0;
            line-height: 25px
        }

        .us .us-two p i {
            display: inline-block;
            margin-right: 25px
        }

        .us .us-two p:last-child {
            margin-bottom: 0
        }
    }

    @media screen and (max-width: 599px) {
        .us {
            padding: 15px
        }

        .us-two h5 {
            font-size: 18px;
            margin-bottom: 10px;
            color: #0075d8
        }

        .us-two p {
            line-height: 25px
        }

        .us-two p i {
            margin-right: 8px
        }

        .us-two .spe {
            font-size: 22px;
            margin-left: -4px;
            margin-right: 5px
        }
    }
</style>