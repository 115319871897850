import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
Vue.use(Vuex)
/**
 * vuex持久化localstorage，尽量使用vuex存储
 * 触发该条件需要通过mutations触发存储
 * */
// const vuexPersisted = persistedState({
// 	storage: {
// 		getItem: (key) => localStorage.getItem(key),
// 		setItem: (key, value) => localStorage.setItem(key, value),
// 		removeItem: (key) => localStorage.removeItem(key)
// 	}
// })

const store = new Vuex.Store({
	modules: {
		user
	},
	plugins: [
		createPersistedState({
			key: 'anters',
			paths: ['user'] // 这里便只会缓存user下的state值
		})
	]
})
export default store